<template>
  <div id="answer-comment-course">
    <div class="div-companies bg-white content-wrapper">
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center flex-wrap">
              <h4 style="color: #0007" class="mt-3">
                <i class="fas fa-graduation-cap" aria-hidden="true"></i>
                Academy - Editar Comentário
              </h4>
              <div>
                <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                  <i class="fa fa-arrow-left mx-1"></i>Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- pergunta -->
      <div class="row m-0 p-0 ml-sm-2">
        <div class="m-0 p-0 ml-2 mr-2 w-100">
          <h1 class="font-weight-bold">{{ comment.question }}</h1>
          <p class="text-muted small m-0 p-0">{{
              `Feita por ${comment.createdBy.name} em ${convertDate(comment.createdAt)} - ${comment.createdBy.company}`
            }}</p>
          <div class="d-flex flex-wrap mt-4 w-100">
            <textarea v-model="newAnswer" class="form-control col-12 col-sm-6" rows="5"
                      placeholder="Digite aqui a NOVA resposta" style="box-shadow: none;"></textarea>
            <div class="col-12 col-sm-6 pl-0 pl-sm-4 mt-3 mt-sm-0">
              <label>Resposta Atual</label>
              <p>{{ comment.answer }}</p>
            </div>
          </div>
          <!-- validacao -->
          <span v-if="(newAnswer.length<10 || newAnswer.length>500) && answerOk"
                class="font-weight-normal ml-1 text-danger" style="font-size: .7rem">(a resposta deve ter entre 10 e 500 caracteres)</span>
          <span v-if="newAnswer.length>475" class="text-small mt-1 w-50"
                :class="newAnswer.length>500 ? 'text-danger':'text-dark'" role="alert" style="font-size: .7rem">Tamanho da resposta: {{
              newAnswer.length
            }}/500</span>
          <span v-if="(newAnswer === comment.answer) && equalAnswer" class="font-weight-normal ml-1 text-danger"
                style="font-size: .7rem">(A resposta atual é igual a anterior. Por favor, verifique a resposta ou clique em cancelar.)</span>
          <div class="d-flex mt-2">
            <button @click="saveAnswer()" class="btn btn-primary mr-1">Responder</button>
            <button @click.prevent="$router.go(-1)" class="btn btn-outline-primary">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // resposta
      newAnswer: '',
      // objeto de comentario
      comment: {
        answered: '',
        answeredBy: '',
        courseId: '',
        createdAt: '',
        createdBy: {
          name: '',
          company: ''
        },
        question: '',
        showComment: Boolean
      },
      // validacao
      answerOk: false,
      equalAnswer: false
    }
  },
  methods: {
    /**
     * Metodo que busca comentario na inicialização
     */
    getCommentById: async function () {
      await this.$tallos_academy_api.get(`comments/${this.$route.params.id}`)
          .then(response => {
            this.comment = response.data
          })
          .catch(() => {
            this.$toasted.global.defaultError({msg: 'Erro na busca do comentário.'})
            this.$router.go(-1)
          })
    },
    /**
     * Esse metodo pega uma data string (2021-02-25T16:26:02.458Z) converte para timestamp e formata usando intl
     * @param {String} date data a ser formatada
     */
    convertDate: function (date) {
      var dateParse = Date.parse(date);
      var currentDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Fortaleza'
      }).format(dateParse)
      return currentDate
    },

    validationBeforeSave: function () {
      this.answerOk = this.newAnswer.length < 10 || this.newAnswer.length > 500 ? true : false
      this.equalAnswer = this.newAnswer === this.comment.answer ? true : false
      return this.answerOk || this.equalAnswer
    },

    /**
     * Metodo que salva uma resposta
     */
    saveAnswer: async function () {
      if (this.validationBeforeSave()) return
      // salvando variaveis
      this.comment.answeredBy = this.$store.getters.admin.name;
      this.comment.answer = this.newAnswer
      // atualizando comentario
      await this.$tallos_academy_api.put(`/comments/admin/${this.$route.params.id}`, this.comment)
          .then(response => {
            this.$toasted.global.defaultSuccess({msg: response.data.message})
            this.$router.go(-1)
          })
          .catch(error => {
            error.response.data.message.forEach(err => {
              this.$toasted.global.defaultError({msg: err})
            })
          })
    }
  },
  mounted: async function () {
    await this.getCommentById()
  }
}
</script>

<style scoped>
* {
  transition: .4s;
}
</style>